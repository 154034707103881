<template>
  <div class="SendEmailInvites">
    <h3><i class="fas fa-paper-plane" />Send Email Invitations</h3>
    <form
      class="form-horizontal"
      @submit.prevent=""
    >
      <div class="form-group m-0">
        <p class="note">
          Separate each email address with a comma
        </p>
        <input
          v-model="txtEmail"
          class="form-control"
          data-add-users="true"
          type="text"
          placeholder="Email address"
          :class="isEmailValid()"
          @keyup="addEmail"
          @keyup.enter="checkEmail"
          @focusout="checkEmail"
        >
        <div
          v-if="error"
          class="form-error-message"
        >
          The email must be a valid email address
        </div>
      </div>
      <div
        v-if="emailList.length > 0"
        class="form-group mb-0 selected-emails"
      >
        <div>
          <div
            v-for="item in emailList"
            :key="item.email"
            class="added-users-user"
            :class="{ 'is-selected': item.isSelected }"
            @click="item.isSelected = !item.isSelected"
          >
            {{ item.email }}
          </div>
        </div>
        <a
          v-if="emailList.filter(item => item.isSelected).length > 0"
          data-delete="users"
          href="#"
          class="delete-users mt-1"
          @click.prevent="deleteSelItem()"
        >
          <icon-trash-primary class="mr-2" />
          Delete selected emails
        </a>
      </div>
      <button
        type="button"
        class="btn btn-primary btn-lg mt-1"
        :disabled="emailList.length === 0"
        @click="sendEmails()"
      >
        <i class="fas fa-paper-plane mr-2" />
        Send Invitations
      </button>
    </form>
  </div>
</template>

<script>
import IconTrashPrimary from '@/components/icons/IconTrashPrimary.vue';
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';
import {callStore} from '@/store/__STORE_call';

export default {
  components: {
    IconTrashPrimary,
  },
  mixins: [CopyToClipboardMixin],
  props: {
    webrtcAlias: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      txtEmail: '',
      emailList: [],
      error: false,
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  computed: {
  },
  methods: {
    copyUrl() {
      this.copyToClipboard(
          this.$refs.txtUrl,
      );
      this.$emit('close-invite');
    },
    directLink() {
      const a = document.createElement('a');
      a.href = this.$router.resolve({
        name: 'welcome-guest',
        params: {
          webrtcAlias: this.webrtcAlias,
        },
      }).href;
      return a.href;
    },
    isEmailValid() {
      return this.reg.test(this.txtEmail);
    },
    addEmail(event) {
      if (event.key === ',') {
        this.error = false;
        const curEmail = this.txtEmail.slice(0, -1);
        if (this.reg.test(curEmail)) {
          if (this.emailList.filter((e) => e.email == curEmail).length == 0) {
            this.emailList.push({
              email: curEmail,
              isSelected: false,
            });
          }
          this.txtEmail = '';
        } else {
          if (this.txtEmail != '') {
            this.error = true;
          }
          this.txtEmail = curEmail;
        }
      }
    },
    checkEmail() {
      if (this.reg.test(this.txtEmail)) {
        if (this.emailList.filter((e) => e.email == this.txtEmail).length == 0) {
          this.emailList.push({
            email: this.txtEmail,
            isSelected: false,
          });
        }
        this.txtEmail = '';
        this.error = false;
      } else {
        if (this.txtEmail != '') {
          this.error = true;
        }
      }
    },
    deleteSelItem() {
      if (this.emailList.filter((item) => item.isSelected).length > 0) {
        this.emailList = this.emailList.filter((item) => !item.isSelected);
      }
    },
    sendEmails() {
      const emailArray = this.emailList.map((e) => e.email);
      if (emailArray.length > 0) {
        const payload = {
          emails: emailArray,
          alias: this.webrtcAlias,
        };
        callStore.sendEmailInvites(payload)
            .then(() => {
              this.emailList = [];
              this.txtEmail = '';
              this.$toast.success('Email Invites Sent!');
              this.$emit('close-email-invites');
            })
            .catch(() => {
              this.$toast.error('Unable to send invites, something went wrong!');
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .input-cover {
    position: relative;

    input {
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  .text-heading {
    white-space: nowrap;
  }

  .link {
    word-break: break-word;
  }

  .invite-title {
    font-size: 34px;
    text-align: center;
  }

  .note {
    margin: 0.5rem 0;
  }

  .btn {
    padding: 1rem 0.75rem;
  }

  .selected-emails {
    border: 1px solid #e6e6e6;
    border-width: 1px 0;
    margin: 1rem 0 0;
    padding: 1rem 0;
  }

  .delete-users {
    display: inline-block;
  }

  .SendEmailInvites {
    input[type=text] {
      width: 60%;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;

      i {
        color: var(--primary-color);
        font-size: 30px;
        margin-right: 1rem;
        vertical-align: middle;
      }
    }
  }
</style>
